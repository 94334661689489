<template>
  <div class="from_box">
      <div class="mt-20">
        <p class="font-13" style="color: #fff;">选择执行器类型</p>
        <div class="borderBase radio_box">
          <el-radio-group v-model="formInfo.astActiveId" @change="taskTypeChange" size="small">
            <el-radio :label="item.id" v-for="item of astList" :key="item.id">{{ item.name }}</el-radio>
          </el-radio-group>
       </div> 
      </div>
      <p class="font-13 mt-10" style="color: #fff;">配置执行任务</p>
      <div class="borderBase radio_box">
      <el-form :model="formInfo" :rules="rules" ref="formInfo" size="small" label-width="70px" class="demo-ruleForm">
          <!-- <el-form-item label="任务名称">
                <el-input
                  class="input_box text_center_input"
                  placeholder="请输入任务名称"
                  v-model="formInfo.name">
                </el-input>
            </el-form-item> -->
            <el-form-item label="控制动作">
              <!-- <el-radio-group v-model="formInfo.controlAction"  size="small">
                <el-radio :disabled="item.funValue == 0" :label="item.funValue" v-for="item of funList" :key="item.funValue">{{ item.name }}</el-radio>
              </el-radio-group> -->
              <radioFun :formInfos='formInfo' :list="funList" ref='funRadio' @change="actionChange"></radioFun>
            </el-form-item>
            <el-form-item label="任务延时">
                  <el-input
                      type="number"
                      class="input_box text_center_input"
                      v-model.number="formInfo.taskDelay">
                      <span slot="suffix" class="unit_box" style="margin-right: 8px;">秒</span>
                  </el-input>
            </el-form-item>
            <el-form-item label="执行时长">
                  
                  <div class="d-flex align-items-center">
                    <el-input
                      type="number"
                      class="input_box time_input text_center_input"
                      v-model.number="formInfo.delay">
                      <el-select class="time-select_box" v-model="formInfo.delayUnit" slot="append" placeholder="选择时间单位">
                        <el-option label="分" :value="2"></el-option>
                        <el-option label="时" :value="3"></el-option>
                      </el-select>
                  </el-input>
                    <HnRemarks class="remarks_box ml-6" width= '160'>
                      
                      <p>设备执行一次动作的时间，0表示一直执行</p>
                    
                    </HnRemarks>
                  </div>
            </el-form-item>
      </el-form> 
      </div>   
     </div>
</template>

<script>
  
  import radioFun from '../../radioFun.vue'
  export default {
    components: {
      radioFun
    },
     props:{
      taskId: String
     },
     data(){
       return {
        
         formInfo:{
           taskDelay: 0,
           controlAction: 1, // 默认开启
           openPercent: 100,
           delayAction: 0, // 默认为0-关，用户不设置
           delay: 0, // 执行时间
           delayUnit: 2, //默认单位 
           taskId: '',
           actuatorIds: [],
           astActiveId: ''
         },
         rules: {},
         funList: [],
         astList: [],
       }
     },
     created(){
       this.reqIotExecutorTypeDropList()
     },
     methods:{
        async reqIotExecutorTypeDropList(){
          const res = await this.$api.IotExecutorTypeWebDropList()

          if(res && res.length){
              this.formInfo.astActiveId = res[0].id
              // this.reqIotControlActuatorTreeList(this.activeName)
              this.astList = res || []
              this.reqIotActuatorFunList()
          }
          
        },
        actionChange(data){
    
          if(!data?.info) return
          this.formInfo.controlAction = data.controlAction
          this.formInfo.openPercent = data.openPercent
          this.$emit('change',data.info)
        },
        async reqIotActuatorFunList(){
           const res = await this.$api.IotExecutorTypeFunList({ id: this.formInfo.astActiveId})
           this.funList = res || []
           this.$nextTick(()=> {
             this.$refs.funRadio.toChange()
           })
        },
        taskTypeChange(){
         
         this.formInfo.controlAction = 1
         this.formInfo.openPercent = 100
         this.reqIotActuatorFunList()
        },
        validData(fun){
          this.$refs.formInfo.validate( async (valid) => {
              
              if (valid && this.formInfo.astActiveId) {
                this.mergeData()
                fun(this.formInfo)
              }else{
                 this.$msg.error('请完善配置')
              } 
          });
        },
        
        mergeData(){
          this.formInfo.taskId = this.taskId 
          // this.formInfo.actuatorIds = this.astInfo.map(item => item.id)
        }
     }
  }
</script>

<style lang="scss" scoped>
.time-select_box{
  width: 80px;
  background-color: #344552;
  border-left: 2px solid #28353F;
  border-radius: 0 5px 5px 0;
}
.time_input ::v-deep .el-input-group__append{
     border: none;
     background-color: #344552;
}
.from_box{
   width: 70%;
   margin: 30px auto 0 auto;
}
.text_center_input ::v-deep .el-input__inner{
    text-align: center;
}
.radio_box{
   padding: 12px 12px 7px 12px;
   box-sizing: border-box;
   border-radius: 8px;
   margin-top: 10px;

}
.el-radio-group ::v-deep .el-radio{
   margin-bottom: 5px !important;
}
</style>