<template>
  <ul class="site-menu wh100 boxScroll">
    <li v-for="item in menuList" :key="item.id">
      <div @click="clickMenu(item)" class="menuName boxBg cp">
        <div>
          <template v-if="isSlot">
            <slot name="leftTools"></slot>
          </template>
          <template v-else>
              <span :class="menuIcon"></span>
          </template>
        </div>
        <div class="sinleline">{{ item.name }}</div>
        <div>
          <template v-if="isSlot">
            <slot name="rightTools"></slot>
          </template>
          <template v-else>
            <i :class="item.isSort ? 'iconfont icon-paixu-jiangxu':'iconfont icon-paixu-shengxu'" :style="rotateStlye(item.isSort)" @click.stop="srotChange(item)" v-if="isEdit"></i>
          </template>
        </div>
      </div>
      <collapse-transition>
        <ul class="site-menu-sub" v-show="item.devs && isOpenId === item.id">
          <li v-for="(child, index) in item.devs" :key="index" @click="clickSubMenu(child)" :class="['site-menu-item', 'cp',
                    isActiveMenu(child) ? 'menu-active-bg-image':'menuItemBg']">
            <span :class="['iconfont', child.iconCls || 'icon-qixiangzhan_',isActiveMenu(child)? 'baseColor':'']"></span>
            <div :class="[isActiveMenu(child)? 'baseColor':'']">
              <p class="sinleline">{{child.devName}}</p>
              <p :class="[isActiveMenu(child)? 'baseColor':'']">{{child.devCode}}</p>
            </div>
            <el-tooltip class="item" effect="dark" :content="netModelName(child)" placement="top">
              <span :class="['iconfont',netModelHanlde(child)]"></span>
            </el-tooltip>

          </li>
        </ul>
      </collapse-transition>
    </li>
  </ul>
</template>

<script>
import CollapseTransition from '@/libs/collapse-transition';
import config from '@/config'
import { splicingActiveMenu } from '@/libs/util'
export default {
  components: {
    'collapse-transition': CollapseTransition,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true
    },
    isSlot: {
      type: Boolean,
      default: false
    },
    menus: {
      type: Array,
      default: () => []
    },
    defaultOpenId: {
      type: String,
    },
    groupState: {
        type: Boolean,
        default: false 
    },
    // activeInfo: {
    //   type: Object,
      
    // }
  },
  watch: {
    // activeInfo: {
    //   handler () {
    //     this.setMenuOpen()
    //   }
    // },
    menus: {
      handler (val) {
        this.menuList = this.addSrotAttr()
        if (this.srotType) {
          this.srotType = false
          return
        }
        this.setMenuOpen()
      },
      deep: true,
      immediate: true
    },
    activeMenu: {
      handler (val) {
        if(!val) return 
        let devCodeObj = splicingActiveMenu(val)
        let data = [devCodeObj,this.menus]
        this.$emit('change', data)
      },
      deep: true
    },
    // defaultOpenMenu: {
    //   handler (val) {
    //     this.setMenuOpen()
    //   },
    //   // immediate: true
    // }
  },
  data () {
    return {
      isOpenId: '',
      activeMenu: '',
      menuList: [],
      sortIcon: ['icon-paixu-jiangxu', 'icon-paixu-shengxu'],
      srotType: false,
      onlineData: config.devStatus.online,
      // isInit: true
    }
  },
  created () {
    // 考虑异步问题，下午监听 menus 解决
    // this.setMenuOpen() // 设置默认开启项
    // this.menuList = this.addSrotAttr()
  },
  // destroyed(){
   
  //   this.clearLocaInfo()
  // },
  computed: {
    menuIcon(){
       return this.groupState ? 'iconfont icon-tongwu-jidi' : 'iconfont icon-tongwu-fenzu1'
    },
    netModelHanlde () {
      return item => {
        const netIcon = config.netModel
        if (netIcon && typeof netIcon === 'object') {
          let icon = netIcon[item.netModel] ? netIcon[item.netModel].icon : 'icon-WIFIxinhao-ji'
          let color = item.onlineStatus == this.onlineData ? 'baseColor' : item.onlineStatus == 2 ? 'standbyColor' : 'offLineColor'
          return `${icon} ${color}`

        }
      }
    },
    netModelName () {
      return item => {
        const netIcon = config.netModel
        if (netIcon && typeof netIcon === 'object') {
          let title = netIcon[item.netModel] ? netIcon[item.netModel].title : ''
          let status = item.onlineStatus == this.onlineData ? '在线' : item.onlineStatus == 2 ? '待机' : '离线'
          return title ? `${title} - ${status}` : status

        }
      }
    },
    isActiveMenu () {
      return dev => {
        if (this.activeMenu == splicingActiveMenu(dev)) return true
        else return false
      }
    },
    rotateStlye () {
      return isSort => isSort ?
        { transition: 'all 0.3s ease 0s', transform: 'rotate(0.5turn)' } :
        { transition: 'all 0.3s ease 0s', transform: 'rotate(0turn)' }
    }
  },
  methods: {
    // 获取本地激活项
    // getLocaInfo(){
     
    //     let data = localStorage.getItem('activeMenu')

    //     let reulst = null
    //     if(data && this.isInit){
          
    //        reulst = JSON.parse(data)
      
    //     } else reulst = this.defaultOpenMenu
    //     this.isInit = false
    //     return reulst
   
    // },
    // clearLocaInfo(){
    
    //     localStorage.removeItem('activeMenu')
    // },
    clickMenu (i) {

      if (this.isOpenId === i.id) {
        this.isOpenId = '';
      } else {
        this.isOpenId = i.id;
      }

    },
    clickSubMenu (dev) {
      let data = [dev.id,dev.devId]
      localStorage.setItem('activeMenu',JSON.stringify(data))
      this.activeMenu = splicingActiveMenu(dev)
 
    },
    setMenuOpen(){
        if(!this.menus || !this.menus.length) return
        let baseAndDev =  this.filterDevId(this.defaultOpenId)
        if(baseAndDev){
          this.isOpenId = baseAndDev.baseId
          this.activeMenu = splicingActiveMenu(baseAndDev) || ''
        }else{
          let parentMenu = this.menus[0]
          this.isOpenId = parentMenu.id
          if(parentMenu.devs && parentMenu.devs.length)
            this.activeMenu = splicingActiveMenu(parentMenu.devs[0]) || ''
        } 
    },
    filterDevId (devId) {
    
      let result = ''
      if (this.menuList.length && devId) {
        this.menuList.some(item => {
         
          if (item.devs && item.devs.length) {
            return item.devs.some(val => {
              if (val.devId === devId) {
                result = {
                  baseId: item.id,
                  devId: val.devId,
                  devCode: val.devCode,
                  id: val.id
                }
                return true
              }
            })
          }
        })
      }
      return result
    },
    addSrotAttr () {
      this.menus.forEach(item => {
        if (!item.hasOwnProperty('isSort')) item.isSort = false
      })
      return this.menus
    },
    srotChange (data) {
      if (data.devs && data.devs.length > 1) {
        data.devs.reverse()
        data.isSort = !data.isSort
        this.srotType = true
        this.isOpenId = id
        if (this.isOpenId !== id) {
          this.isOpenId = id
        } else {
          this.isOpenId = ''
          setTimeout(() => {
            this.isOpenId = id
          }, 500);
        }
      } else {
        // this.$msg.info('设备数量 < 2,无法排序！')
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.site-menu {
  font-size: 14px;
  color: #fff;
  padding-right: 5px;
  & > li {
    .menuName {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      & > div:nth-child(2) {
        flex: 1;
        text-align: left;
        margin-left: 10px;
        max-width: 240px;
      }
      & i {
        color: #dcdfe6;
        font-size: 18px;
      }
    }
    .site-menu-item {
      width: 100%;
      height: 60px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      & > span {
        display: block;
      
        flex-shrink: 0;
      }
      & > span:first-child {
        width: 30px;
        font-size: 30px;
        margin: 0 8px;
      }
      & > span:last-child {
        font-size: 18px;
        width: 24px;
        margin: 0 8px;
      }
      & > div {
        flex: 1;
        min-width: 0;
        // max-width: 170px;
        & > p:last-child {
          width: 100%;
          color: #dcdfe6;
          font-size: 12px;
        }
      }
    }

  }
  & > li:not(:first-child) .menuName {
    margin-top: 6px;
  }
  & > li:first-child .menuName {
    border-radius: 8px 8px 0 0 !important;
  }
  & > li:last-child .menuName {
    border-radius: 0 0 8px 8px;
  }
}
</style>