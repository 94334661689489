<template>
  <div>
    <!--     :delay="1000" -->
    <draggable
    draggable=".draggable"
		class="card grid-container"
		v-model="draggableList"
		item-key="id"
		animation="300"
    @end="endDraggable"
		chosenClass="chosen"
	>
			<div 
      :class="['item','d-flex','flex-column','draggable','boxBg',checkInfo.includes(item.id) ? 'apply-shake': '']"
       v-for="(item,i) in draggableList"
       @click.stop.prevent="toAddCheck(item.id)" 
       :key="item.id">
				 <div class="item_head_box">
            <div class="d-flex align-items-center">
               <span class="el-icon-check active round cp" @click.stop.prevent="delCheck(item.id)" v-if="checkInfo.includes(item.id)"></span>
               <span class="order_icon round" v-else>{{ i + 1}}</span>
               <span>{{ item.actuatorName }}</span>
            </div>
            <div>
              <span class="el-icon-edit cp mr-6" @click.stop.prevent="toEditPlan(item)"></span>
               <span class="el-icon-delete cp" @click.stop.prevent="toDelPlan(item.id)"></span>
            </div>
         </div>
         <div class="item_body_box">
          <p class="font-12 tc mv-12">{{ item.devName }}</p>
          <div class="d-flex">
            <div class="d-flex flex-column justify-content-center align-items-center actuator_icon_box">
  
                <template v-if="isOpenIcon(item,'actuatorOpenPercent')">
                    <el-image :src="gifUrl(item.workIcon)" class="hn-img">
                      <div slot="error" class="clt-icon-error-slot iconfont icon-zhanweifu">
                      </div>
                    </el-image>
                </template>
                <template v-else>
                    <span :class="[ item.channelStatus > 2 ? 'standbyColor' : '',item.icon || 'iconfont icon-zhanweifu'] "></span>
                </template>
                <p :class="[actuatorStatusTextColor(item,'actuatorOpenPercent'),'font-12']" style="margin-top: 4px;">{{ actuatorStatusText(item,'actuatorOpenPercent') }}</p>
            </div>
            <div class="actuator_info_box">
                
                <div>
                  <span>执行操作：</span>
                  <span class="text_style">{{ controlAction(item) }}</span>
                </div>
                <div>
                  <span>执行时长：</span>
                  <span class="text_style">{{ delayTime(item) }}</span>
                </div>
                <div>
                  <span>延时时长：</span>
                  <span class="text_style">{{ taskDelay(item)}}</span>
                </div>
            </div>
          </div>
         </div>
         <carryOutLoad :text="isLoadingText(item)" :isShow='item.isCtl' @rest='testRest(item.id)' @cancel="cancelLoading(item.id)" :status="item.ctlStatus"></carryOutLoad>
			</div>
		  <div  class="boxBg item">
         <!-- <addTaskPlan ref="addTaskRef" :taskId="taskId"></addTaskPlan> -->
         <div class="wh d-flex align-items-center justify-content-center" @click="toAddPlan">
            <span class="el-icon-plus font-28"></span>
          </div>
      </div>
      
	</draggable>
 
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  // import addTaskPlan from '../addTaskPlan.vue'
  import addTaskFrom from '../addTaskFrom.vue'
  import editTaskFrom from '../editTaskFrom.vue'
  import carryOutLoad from '../carryOutLoad.vue'
  import bus from '@/libs/eventBus.js'
  import taskCtlMixin from './taskCtlMixin'
  import actuatorStutas from '../../../actuatorStutas'
  import { nanoid } from 'nanoid'
  
  export default {
    mixins: [taskCtlMixin,actuatorStutas],
    components: {draggable,carryOutLoad},
    props:{
      list:{
        type: Array,
        default:() => []
      },
      taskId: String
    },
    watch:{
      'list':{
        handler (val){
           this.draggableList = val.map(item=> {
              return { 
                ...item, 
                isCtl: false,
                ctlStatus: ''
              }
           })
          //  当数据变动时，更新nanoid，并结束订阅
           this.nanoid = nanoid()  
           if(this.activeTopic) this.pauseTopic()
        },
        immediate: true
      }
    },
    data(){
      return {
        checkInfo:[],
        draggableList: [],
        activeTopic: null, //当前订阅对象
        nanoid: '',
        isColse: false
      }
    },
    destroyed(){
      if(this.activeTopic) this.pauseTopic()  
    },
    computed:{
      taskDelay() {
        return item => !item.taskDelay  ? '立即执行' : item.taskDelay + '秒'
      },
      delayTime(){
         return item => {
            const unitTime = this.$formatter(item.delayUnit,'delayUnit')
            return !item.delay ? '一直执行' : item.delay + unitTime
         }
      },
      isLoadingText(){
         return item => {
           return this.isColse ? item.closeChannelName : this.controlAction(item)
         }
      },
      controlAction(){
         return (item) => {
           let typeList = item ?.funs
           if(!typeList || !typeList.length) return ''
           let result = ''
           typeList.some(v => {
            
              if(v.funValue == item.controlAction && v.openPercent == item.openPercent) return result = v.name
           })
           return result

         }
      },
      ctlActiveTaskId() { return this.$store.getters.ctlActiveTaskId },  
    },
    methods:{
      toAddPlan(){
    
        if(!this.ctlActiveTaskId || this.ctlActiveTaskId == 'add') return this.$msg.info('请先添加任务')
        this.showDialog(addTaskFrom,{
          props:{
            taskId: this.taskId
          }
         },{
          width:'620px',
          title: '新增任务命令',
          dialog:{
            isBut: false
          }
        },()=> {
            
        })
        
      },
      toEditPlan(row){
        this.showDialog(editTaskFrom,{
          props:{
            taskId: this.taskId,
            row,
          }
         },{
          width:'620px',
          title: `编辑执行器 - ${row.actuatorName} - 任务命令`,
          // dialog:{
          //   isBut: false
          // }
        },()=> {
            
        })
      },
      toDelPlan(id){
        this.$confirm(`您正在删除执行任务，确认继续？`, "提示").then( async () => {
             const res = await this.$api.DelIotTaskCmd({id})
             if(res) bus.$emit('updateData')
        })
      },
      toAddCheck(id){
        if(!this.checkInfo.includes(id)) this.checkInfo.push(id)  

      },
      endDraggable(d){
        if(d.newIndex == d.oldIndex) return
        let ids = this.draggableList.map(item => item.id)
        bus.$emit('showSaveSrot',ids)
      },
      devTopicUpdate(ObjFrame){
 
           ObjFrame.executors.map(info => {
                this.draggableList.map(actuator => {
                    if(actuator.code == ObjFrame.devId && actuator.actuatorCode == info.no){
                        if(actuator.isReversible 
                          && this.isColse
                          && actuator.actuatorOpenPercent !== 0
                          && actuator.actuatorOpenPercent == info.percentage
                        ) return
                        if(actuator.isReversible  // 当为正反转
                           && !this.isColse
                           && actuator.actuatorOpenPercent !== actuator.openPercent // 当执行器当前开合度不等于目标开合度时
                           && actuator.actuatorOpenPercent == info.percentage) return  // 当执行器开合度等于回调数据时，满足三个条件，说明是该条是执行器开始执行的命令，直接返回
                        if(actuator.isCtl){
                          this.delCheck(actuator.id)
                          
                        }else{
                          actuator.isCtl = true
                        }
                        actuator.ctlStatus = 'success'
                        setTimeout(() => {
                          actuator.channelStatus = actuator.isReversible && info.status < 3 ? info.percentage == 0 ? 2 : info.percentage == 100 ? 1 : 0 : info.status
                          actuator.actuatorOpenPercent = info.percentage
                          actuator.isCtl = false
                          actuator.ctlStatus = ''
                        }, 1000);
                    } 
                })
           })
      },
      // 全选
      allCheck(){
         this.checkInfo = this.list.map(item => item.id)
      },
      // 删除指定选中
      delCheck(id){
      
        let i = this.checkInfo.indexOf(id)
        if(i > -1){
          this.checkInfo.splice(i,1)
          this.$emit('checkChange')
        } 

      },
      // 取消全选
      cancelCheck(){
        this.checkInfo = []
      }
    }
  }
</script>

<style lang="scss" scoped>
.grid-container {
  margin: 5px 5px 0 5px;
  padding: 15px;
  box-sizing: border-box;
	display: grid;
	grid-template-columns: repeat(auto-fill,minmax(280px, 1fr));
  grid-gap: 10px 10px;
	grid-template-rows: 190px;
  grid-auto-rows: 190px;
	height: calc(100% - 10px);
  overflow-y: auto;
	.item {
		box-sizing: border-box;
		border: 1px solid var(--el-color-primary);
    border-radius: 10px;
    position: relative;
    .item_head_box{
       height: 34px;
       display: flex;
       align-items: center;
       justify-content: space-between;
       padding: 0 10px;
       box-sizing: border-box;
       font-size: 14px;
       border-bottom: 1px solid #15232D;
       flex-shrink: 0;
       .round{
        display: block;
         width: 16px;
         height: 16px;
         border-radius: 10px;
         
         font-size: 12px;
         text-align: center;
         line-height: 16px;
         flex-shrink: 0;
         margin-right: 10px;
       }
       .order_icon{
         border: 1px solid var(--el-color-primary);
         color: var(--el-color-primary);
       }
       .check_icon{
          background-color: var(--el-color-primary);
          color: #15232D;
       }
    }
    .item_body_box{
       flex: 1;
       .actuator_icon_box{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
          & >span{
              font-size: 74px;
              color: #999;
          }
          & > .hn-img {
              width: 74px;
            //   height: 88px;
          }
          
       }
       .actuator_info_box{
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          & > div:not(:first-child){
             margin-top: 12px;
          }
          & > div{
             display: flex;
             align-items: center;
             justify-content: center;
          }
          .text_style{
             min-width: 60px;
             padding: 0 5px;
             height: 24px;
             line-height: 24px;
             text-align: center;
             background-color: var(--el-color-primary);
             border-radius: 5px;
             font-size: 12px;
          }
       }
    }
	}
}

.chosen {
	border: 2px dashed var(--el-color-primary) !important;
}
@keyframes shake {
  10%, 90% {
      transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
      transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
      transform: translate3d(4px, 0, 0);
  }
}

.apply-shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) infinite both !important;
  border: 1px dashed var(--el-color-primary) !important;
  box-shadow:inset 5px 5px 20px 20px rgba(var(--sys-color-rgb-primary),.1);
}

</style>