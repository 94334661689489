<template>
    <div class="autoSearch_box">
        <el-autocomplete
            :class="['inline-input',isScrollMargin ? 'scrollMar' : '']"
            v-bind="$attrs"
            v-on="$listeners"
            v-model="inputVal"
            ref="cautocomplete"
            :fetch-suggestions="querySearch"
            clearable
            :trigger-on-focus="false"
            >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <template slot-scope="{ item }">
                <slot :row="item"></slot>
            </template>
        </el-autocomplete>
       
    </div>
</template>

<script>
    import { deepClone } from '@/libs/util'
    export default {
        props:{
            value: String,
            filterList:{
                type: Array,
                default: () => []
            },
            setValue:{
                type: String,
                default: 'value'
            },
            isScrollMargin: {
                type: Boolean,
                default: false
            },
            fetchSuggestions:{
                type: Function
            }
            // styleOptions:{
            //     type: String,
            //     default: () => ({})
            // }
        },
        data(){
            return{
                inputVal: '',
            }
        },
        created(){
            this.inputVal = this.value
        },
        computed:{
           handleData(){
               let result = []
               if(this.filterList.length){
              
                   let key = this.setValue
                   result = deepClone(this.filterList)
                   result.forEach(item => {
                      if(!item.hasOwnProperty(key)) return
                      item.value = item[key]
                   })
               }
              
               return result
           }
        },
        watch:{
            value(val){
                this.inputVal = val
            }
        },
        methods:{
            querySearch(queryString, cb) {
                if(this.fetchSuggestions && typeof this.fetchSuggestions == 'function'){
                    this.fetchSuggestions(queryString, cb)
                    return
                }

                var restaurants = this.handleData
       
                // this.restaurants = restaurants
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
              
                cb(results);
            },
            createFilter(queryString) {
                return (restaurant) => {
                    // 这里可以通过value 和 id一起判断 
                  return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1 
                       
                };
            },
            active(reuslt = false){
                this.$refs.cautocomplete.activated = reuslt
            }
        }
    }
</script>

<style lang="scss" scoped>
.autoSearch_box{
    width: 1;
}
.el-autocomplete.inline-input{
   border: 1px solid rgba(var(--sys-color-rgb-primary), 0.4);
   border-radius: 3px;
   width: 100%;
}
.scrollMar{
  width: calc(100% - 5px) !important;
}
.el-autocomplete ::v-deep .el-input__inner{
        background: none;
      
        color: #fff;
}
.el-autocomplete ::v-deep .el-input__inner:focus{
    border-color: var(--el-color-primary) !important;
}
</style>