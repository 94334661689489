<template>
      <vue-seamless-scroll
        :data="listData"
        :class-option="rollOption"
        class="nowData_content_box"
        :key="timer"
      >
      <ul class="nowDataList"  >
          <li class="nowDataItem" v-for="(item,i) of listData" :key="i">
              <div>
                  <div :style="paramOutIconHanlde(i)"></div>
                  <span :class="['iconfont', item.paramIcon]" :style="paramInIconHanlde(i)"></span>
              </div>
              <div>
                  <p>
                      <span :class="item.paramName !== '风向'?'paramNub':'paramWind'">{{ paramValueHandle(item) }}</span>
                      <span>{{ item.paramName === '风向'?'': item.paramUnit }}</span>
                  </p>
                  <p>{{ item.paramName }}</p> 
              </div>
          </li>


      </ul>
      </vue-seamless-scroll>
</template>

<script>
  export default {
     props:{
      listData:{
          type: Array,
          default: () => []
        }
     },
    
     data(){
       return {
        rollOption:{
          direction: 3,
        },
        timer: (new Date()).getTime(),
        paramColorList:[
                {
                    iconUrl: require('@/assets/imgs/monitoring/default/circle.png'),
                    iconColr:'#00ffc5'
                },
                {
                    iconUrl: require('@/assets/imgs/monitoring/default/circle1.png'),
                    iconColr:'#00ccff'
                },
                {
                    iconUrl: require('@/assets/imgs/monitoring/default/circle2.png'),
                    iconColr:'#ffa800'
                },
                {
                    iconUrl: require('@/assets/imgs/monitoring/default/circle3.png'),
                    iconColr:'#a54ed7'
                },
                {
                    iconUrl: require('@/assets/imgs/monitoring/default/circle4.png'),
                    iconColr:'#435ae6'
                }
        ] 
    
       }
     },
     computed:{
         paramInIconHanlde(){
            return index => {
                let i = this.paramColorList.length > index ? index : index%4
                let data = this.paramColorList[i]
                  return {
                      color: data.iconColr,
                      fontSize: '30px'
                  }
            }
         },
         paramOutIconHanlde(){
            return index => {
              let i = this.paramColorList.length > index ? index : index%4
              let data = this.paramColorList[i]
              return {
                  backgroundImage: `url(${data.iconUrl})`,
                  backgroundRepeat: 'no-repeat',
              }
            }
         },
         paramValueHandle(){

              return item => {
                  if(item.paramName.includes('风向')){
                  
                    let valArr = [
                        {
                            max:112.5,
                            min:67.5,
                            name: '北风'
                        },
                        {
                            max:157.5,
                            min:112.5,
                            name: '西北风'
                        },
                        {
                            max:202.5,
                            min:157.5,
                            name: '西风'
                        },
                        {
                            max:247.5,
                            min:202.5,
                            name: '西南风'
                        },
                        {
                            max:292.5,
                            min:247.5,
                            name: '南风'
                        },
                        {
                            max:337.5,
                            min:292.5,
                            name: '东南风'
                        },
                        {
                            max:22.5,
                            min:337.5,
                            name: '东风'
                        },
                        {
                            max:67.5,
                            min:22.5,
                            name: '东北风'
                        }
                    ]
                    let val = parseInt(item.paramVal)
                    let resultName = '无风'
                
                    valArr.some(item => {
                        
                        if(val > 337.5 || val <= 22.5){
                            resultName= '东风'
                            return true
                        } 
                        if(val> item.min && val<= item.max){
                            resultName= item.name
                            return true
                        }
                    })
                    return resultName
                
                  }else {
                      return item.paramVal
                  }
              }
         }
     },
     methods:{
        update(){
          this.$nextTick(()=> {
            this.timer= (new Date()).getTime()
          })
        }
     }
  }
</script>

<style lang="scss" scoped>
.nowData_content_box{
    width: calc(100% - 40px);
    height: 100px;
    padding: 20px 0;
    margin: 0 20px;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    .nowDataList{
        height: 100%;
        // position: absolute;
        // left: 0;
        // top: 0;     
        display: flex;
        & > li {
            min-width: 261px;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            & > div:first-child{
                width: 60px;
                height: 60px;
                position: relative;
                & > div{
                  width: 100%;
                  height: 100%;
                  animation:turn 4s linear infinite;
                }
                & > span{
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform:translate(-50%,-50%);
                  
                }                     
            }
            & > div:last-child{
                margin-left: 10px;
                & > p:first-child {
                    color: #fff;
                    font-size: 14px;
                    .paramNub{
                        font-size: 30px;
                        font-family: 'agencyr';
                    }
                    .paramWind{
                        font-size: 22px;
                    }
                }
                & > p:last-child {
                    font-size: 14px;
                    color: rgba(255,255,255, 0.4);    
                }
            }
        }
        & > li::before{
            width: 1px;
            height: 80px;
            display: block;
            background: linear-gradient(
            to top, 
            rgba(0,0,0,0) 0%,  
            #fff 50%, 
            rgba(0,0,0,0) 100%);
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -40px;
            content: '';
        }
    }
}
</style>