<template>
  <div class="task_info_box borderBase boxBg">
     <div class="task_header_box">
        <span class="baseColor font-16">定时编号{{ index + 1 }}</span>
        <div class="d-flex align-items-center">           
           <!-- <fromItem :isEdit="true" :infos="ctlForm" :taskId="taskId"></fromItem> -->
           <span class="el-icon-edit c999 cp" @click="toEdit"></span>
           <span class="el-icon-delete c999 cp ml-6" @click="delRow"></span>
        </div>
     </div>
     <div class="task_content_box">
        <img class="icon_box" :src="iconImg"/>
        <div class="line_box"></div>
        <div class="info_box">
          <div class="row_box">
            <span class="label_box">定时模式：</span>
            <span class="baseColor">{{ modelText }}</span>
          </div>
          <template v-if="ctlForm.isLoop">
            <div class="row_box">
              <span class="label_box">执行频率：</span>
              <span class="baseColor">{{ ctlForm.loopTime }}</span>
            </div>
            <!-- <div class="row_box">
              <span class="label_box">执行频率：</span>
              <span class="baseColor">{{ ctlForm.loopMin }}分钟</span>
            </div> -->
          </template>
          <div class="row_box" v-else>
            <span class="label_box">执行时间：</span>
            <span class="baseColor">{{ ctlForm.implementTime }}</span>
          </div>
          
          <div class="row_box">
            <span class="label_box">执行日期：</span>
            <span class="baseColor">{{ weekText }}</span>
          </div>
          
          <div class="row_box">
            <span class="label_box">是否启用：</span> 
            <div>
                  <el-switch
                  v-model="ctlForm.status"
                  :active-value="1"
                  :inactive-value="2"
                  @change="switchChange"
                  :active-color="$themeColor"
                  inactive-color="#343F48">
                  </el-switch>
                  <span :class="['switch_text',ctlForm.status == 1 ? 'baseColor':'']">{{ statusText }}</span>
              </div>
          </div>
        </div>
     </div>
  </div>
</template>

<script>
  import fromItem from './fromItem.vue'
  import bus from '@/libs/eventBus.js'
  export default {
    // components: { fromItem },
    props:{
       infos:Object,
       index:{
         Number,
         default: 0
       },
       taskId: String
    },
    data(){
      return {
         iconImg: require('@/assets/imgs/control/default/time.png'),
         ctlForm: {
          id: '',
          cron: '',
          taskId: '',
          status: 0,
          loopMin: 0,
          loopTime: '',
          implementTime: '',
          week: [],
          isLoop: true
        },
        weekDays:[
            {
                value: '1',
                label: '一'
            },
            {
                value: '2',
                label: '二'
            },
            {
                value: '3',
                label: '三'
            },
            {
                value: '4',
                label: '四'
            },
            {
                value: '5',
                label: '五'
            },
            {
                value: '6',
                label: '六'
            },
            {
                value: '7',
                label: '日'
            },
        ],
      }
    },
    watch:{
      infos:{
        handler (val){
            if(val) this.hanldeData(val)
         },
         immediate: true
      }
    },
    computed:{
      modelText(){
         return this.ctlForm.isLoop ? '循环模式' : '定时模式'
      },
      weekText(){
        let text = ''
        this.ctlForm.week.map((item,i) => {
          this.weekDays.map(val => {
             if(val.value == item){
               text += `周${val.label}`
               if(i < (this.ctlForm.week.length - 1))text += '、' 
               
             }
          })
          
        })
         return text
      },
      statusText(){
          return this.ctlForm.status == 1 ? '启用':'停用'
       }
    },
    methods:{
       toEdit(){
         this.showDialog(fromItem,{
          props:{
            infos: this.ctlForm,
            taskId: this.taskId,
            isEdit: true
          }
         },{
          width:'500px',
          title: '编辑定时控制',
          // dialog:{
          //  isBut: false
          // }
        },()=> {
            
        })
       },
       hanldeData(infos){
           let timeData = this.hanldeTime(infos.cron)
           Object.assign(this.ctlForm,infos,timeData)
           
       },
       hanldeTime(cron){
          if(!cron) return
          let cronInfo = cron.split(" ")
          let minStr = cronInfo[1]
          let hoursStr = cronInfo[2]
          let weekStr = cronInfo[5]
          let returnData = {
            // loopMin: 0,
            loopTime: '',
            implementTime: '',
            week: [],
            isLoop: true
          }
          // 1.判断是否为循环任务
          const isMinLoop = minStr.includes('/')
          const isHoursLoop = hoursStr.includes('/')
          returnData.isLoop = isMinLoop || isHoursLoop
          // 2.处理分
          if(returnData.isLoop){
            returnData.loopTime = isMinLoop ? parseInt(minStr.split('/')[1]) + '分钟' : isHoursLoop ? parseInt(hoursStr.split('/')[1]) + '小时' : ''
          }else{
            returnData.implementTime = `${hoursStr}:${minStr}`
          }
          // 3.处理周
          if(weekStr.includes(',')){
             returnData.week = weekStr.split(',')
          }else if(weekStr && weekStr != '*'){
             returnData.week = [weekStr]
          }
          return returnData
       },
       switchChange(){
          this.$confirm(`您正在${this.statusText}联动条件，确认继续？`, "提示").then(()=> {
              this.$api.EditIotTaskTimerStatus({id: this.infos.id}).then(res => {
                  if(res){
                    this.$msg.success('设置成功')
                    bus.$emit('updateData','time')
                  }else this.ctlForm.status = this.ctlForm.status == 1 ? 2 : 1
              })
          }).catch(()=> {
   
              this.ctlForm.status = this.ctlForm.status == 1 ? 2 : 1
      
          })
       },
       delRow(){
        this.$confirm(`您正在删除联动任务，确认继续？`, "提示").then(()=> {
           this.$api.DelIotTaskTimer({id: this.infos.id}).then(res => {
               if(res){
                 bus.$emit('updateData','time')
                 this.$msg.success('删除成功')
               }
           })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.task_info_box{
  width: 370px;
  height: 340px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .task_header_box{
     height: 42px;
     padding: 0 20px;
     box-sizing: border-box;
     display: flex;
     align-items: center;
     justify-content: space-between;
     border-bottom: 1px solid #15232D;
  }
  .task_content_box{
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon_box{
      width: 64px;
      height: auto;
      margin: 20px 0;
    }
    .line_box{
      width: 100%;
      height: 2px;
      background-image: linear-gradient(to right, #28353F,#00D1A1,#17BFD4,#28353F)
    }
    .info_box{
       flex: 1;
       min-height: 0;
       margin: 20px 0;
       .row_box{
          display: flex;
          font-size: 14px;
          .label_box{
             width: 70px;
             text-align: right;
          }
       }
       .row_box:not(:first-child){
          margin-top: 8px;
       }
    } 
    .add_style{
       display: flex;
       align-items: center;
       justify-content: center;
       font-size: 40px;
       color: #999;
    }
  }
  .c999{
    color: #999;
  }
  .switch_text{
   margin-left: 12px;
   color: #999;
 }
 .ml-6{
   margin-left: 6px;
 }
}
</style>