<template>
 <div class="timeFrom_box mt-20">
          
      <div class="from_box">
          <el-form :model="ctlForm" :rules="rules" ref="ctlForm" size="small" label-width="100px" class="demo-ruleForm">
            <el-form-item label="模式选择">
              <el-radio-group v-model="ctlForm.isLoop" size="mini">
                <el-radio :label="true" border>循环模式</el-radio>
                <el-radio :label="false" border>定时模式</el-radio>
              </el-radio-group>
            </el-form-item>
            
            <el-form-item label="时间选择" prop='week'>
                <week v-model="ctlForm.week"></week>
            </el-form-item>
            <el-row>
              <el-col :span="14">
        
                        <template v-if="ctlForm.isLoop" prop="loopTime">
                          <el-form-item label="执行频率" prop="loopTime">
          
                              <el-cascader
                              style="width:220px"
                              v-model="ctlForm.loopTime"
                              :options="loopTimeOption"
                              :show-all-levels="false"
                              ></el-cascader>
                          </el-form-item>
                          <!-- <el-form-item label="执行时间" >
                                <el-time-select
                                    v-model="ctlForm.loopTime"
                                    placeholder="时间"
                                    value-format="HH:mm"
                                    format="HH:mm"
                                    style="width:100%"
                                    :clearable="false"
                                    :picker-options="{
                                        start: '00:00',
                                        step: '01:00',
                                        end: '23:00'
                                    }"
                                >
                                </el-time-select>
                          </el-form-item>
                          <el-form-item label="执行频率" prop="loopMin">
                                <el-input
                                    type="number"
                                    class="input_box"
                                    v-model.number="ctlForm.loopMin">
                                    <span slot="suffix" class="unit_box">分钟</span>
                                </el-input>
                          </el-form-item> -->
                        </template>
                        <template v-else>
                          
                            <el-form-item label="执行时间" prop="implementTime" >
                                <el-time-picker
                                    v-model="ctlForm.implementTime"
                                    placeholder="时间"
                                    value-format="HH:mm"
                                    format="HH:mm"
                                    style="width:220px"
                                    :clearable="false"
                                   
                                >
                                </el-time-picker>
                            </el-form-item>
                        </template>
              
              </el-col>
            </el-row>
            <el-form-item label="状态">
                <el-switch
                v-model="ctlForm.status"
                :active-value="1"
                :inactive-value="2"
                :active-color="$themeColor"
                inactive-color="#343F48">
                </el-switch>
                <span :class="['switch_text',ctlForm.status == 1 ? 'baseColor':'','ml-12']">{{ ctlForm.status == 1 ? '启用':'停用' }}</span>
            </el-form-item> 
          </el-form> 
      </div>
      <HnSyncLoading :loading="perLoading" :erroStatus='erroStatus' width='80' types="line"/>
        
 </div>
</template>

<script>
  import { deepClone,mapTo } from '@/libs/util'
  import week from '@/components/localComponent/week.vue'
  import cronMixin from '../../../actuator-list/components/model-ctl/inputCron/cronMixin'
  import bus from '@/libs/eventBus.js'
  export default {
    mixins: [cronMixin],
    components: {
      week,
    },
    props: {
      infos: Object,
      taskId: String,
      isEdit:{
        type: Boolean,
        default: false
      }
    },
    data(){
      const nowTime = this.$day().format('HH:mm')
      const arrayEmpty = (rule, value, callback) => {
        if (!Array.isArray(value)) callback(new Error('数据格式为数组'))
        if (value.length) {
          callback()
        } else {
          callback(new Error('请选择日期'))
        }
      }
      const loopMin = (rule, value, callback) => {
        if (!value || (value < 1 || value > 59)) callback(new Error('请输入1-59分钟的时间值'))
        else {
          callback()
        }
      }
      return {
        // dialogVisible:false,
        ctlForm: {
          id: '',
          cron: '',
          taskId: '',
          status: 1,
          loopTime:['min',5],
          implementTime: nowTime,
          week: ['1','2','3','4','5','6','7'],
          isLoop: true
        },
        erroStatus: false,
        perLoading: false,
        checkTime: '3',
        loopTimeOption: [
            {
              value: 'min',
              label: '分钟',  
              children: [
                {
                  value: 5,
                  label: '5分钟'
                },
                {
                  value: 10,
                  label: '10分钟'
                },
                {
                  value: 15,
                  label: '15分钟'
                },
                {
                  value: 20,
                  label: '20分钟'
                },
                {
                  value: 30,
                  label: '30分钟'
                },
              ]
            },
            {
              value: 'hours',
              label: '小时',  
              children: [
                {
                  value: 1,
                  label: '1小时'
                },
                {
                  value: 2,
                  label: '2小时'
                },
                {
                  value: 3,
                  label: '3小时'
                },
                {
                  value: 4,
                  label: '4小时'
                },
                {
                  value: 5,
                  label: '5小时'
                },
                {
                  value: 6,
                  label: '6小时'
                },
                {
                  value: 7,
                  label: '7小时'
                },
                {
                  value: 8,
                  label: '8小时'
                },
                {
                  value: 9,
                  label: '9小时'
                },
                {
                  value: 10,
                  label: '10小时'
                },
                {
                  value: 11,
                  label: '11小时'
                },
                {
                  value: 12,
                  label: '12小时'
                }
              ]
            }
        ],
        rules: {
         
            week: [
              { validator: arrayEmpty, trigger: 'blur', required: true }

            ],
            loopMin: [
            //  { required: true, message: '请选择执行频率', trigger: 'blur' },
             { validator: loopMin, trigger: 'blur', required: true }
            ],
            
          
        },
        ctlTimeType: '3'
      }
    },

    created(){ 
      if(this.isEdit) this.analysisData() 
    },
    submit (dialog, callback) {
      this.$refs.ctlForm.validate( async(valid) => {
        if (valid) {
         let data = this.mergeData() 
         let api = this.isEdit ? 'EditIotTaskTimer' : 'AddIotTaskTimer'
         const res = await this.$api[api](data)
         if(res){
           bus.$emit('updateData','time')
           callback();
           dialog.okLoading = false;
           dialog.close();
           setTimeout(() => {
            this.$msg.success('设置成功')
           }, 500);
           
         }
          
        } 
      });
   
   },
    methods:{
      timeChange (data) {
        
        if (data) {
          let dataList = data.split(':')
          this.minutesText = dataList[1]
          this.hoursText = dataList[0]
        }
      },
      loopTimeChange() {
        // if (data) {
        //   let dataList = data.split(':')
        //   this.hoursText = dataList[0]
        //   this.minutesText = this.ctlForm.loopMin ? `0/${this.ctlForm.loopMin}` : 0
        // }
        let [loopTimeType,timeData] = this.ctlForm.loopTime

        if(loopTimeType == 'min'){
          this.minutesText = timeData ? `0/${timeData}` : 0  
          this.hoursText = '*'
          return
        }
        if(loopTimeType == 'hours'){
          this.hoursText =  timeData ? `0/${timeData}` : 0 
          this.minutesText = ''
        }
      },
      timeDataToCron(){
        if(this.ctlForm.isLoop){
           this.loopTimeChange()
        }else{
           this.timeChange(this.ctlForm.implementTime)
        }
      },
      // 整合请求数据
      mergeData(){
        let ctlForm = this.ctlForm
        // let cloneData = deepClone(ctlForm)
        // 转化周数据    
        this.weeksDataToCron(ctlForm.week)
        // 处理日期数据
        this.timeDataToCron()
        return {
          cron: this.cronStr,
          taskId: this.taskId,
          status: ctlForm.status,
          id: ctlForm.id
        }
        
      },
      hanldeTime(cron){
        if(!cron) return
        let cronInfo = cron.split(" ")
        let minStr = cronInfo[1]
        let hoursStr = cronInfo[2]
        let weekStr = cronInfo[5]
        let returnData = {
          // loopHours: '12:00',
          loopTime: ['min',5],
          implementTime: this.$day().format('HH:mm'),
          week: [],
          isLoop: true
        }
        // 1.判断是否为循环任务
        
        const isMinLoop = minStr.includes('/')
        const isHoursLoop = hoursStr.includes('/')
        returnData.isLoop = isMinLoop || isHoursLoop
        // 2.处理循环任务
        if(returnData.isLoop){
          returnData.loopTime = isMinLoop ? ['min', parseInt(minStr.split('/')[1]) ] : isHoursLoop ? ['hours', parseInt(hoursStr.split('/')[1]) ] : ['min',5]
        }else{
          returnData.implementTime = `${hoursStr}:${minStr}`
        }
        // 3.处理周
        if(weekStr.includes(',')){
            returnData.week = weekStr.split(',')
        }else if(weekStr && weekStr != '*'){
            returnData.week = [weekStr]
        }
        return returnData
      },
      // 解析数据
      analysisData (){
        const infos = deepClone(this.infos)
        let timeData =  this.hanldeTime(infos.cron)
        let analysisInfos = Object.assign({},infos,timeData)
        
        mapTo(analysisInfos,this.ctlForm)
        // Object.keys(this.ctlForm).map(key => {
        //     switch(key){
        //       case 'loopTime': 
        //         if(!infos[key]) this.ctlForm[key] = '12:00'
        //         break;
        //       case 'implementTime':
        //         if(!infos[key]) this.ctlForm[key] = this.$day().format('HH:mm')
        //         break;
        //       case 'loopMin':
        //         if(!infos[key]) this.ctlForm[key] = 1
        //         break;
        //       default:
        //         this.ctlForm[key] = infos[key]
        //         break;  
        //     }
        // })
      
      },
      
    }
  }
</script>

<style lang="scss" scoped>
.c999{
    color: #999;
  }
  .but_box{
          text-align: right;
          margin-bottom: 0 !important;
      }
</style>