<template>
  <div class="assignment_menu boxScroll" ref="menuRef">
     <div 
      @click="change(item)"
      v-for="item in menuList" 
      :key="item.id" 
      :class="['menu_item_box','cp','taskItem',ctlActiveTaskId == item.id ? 'menu-active-bg-image':'menuItemBg']">
        <span class="font-14">{{ item.name }}</span>
        <el-popconfirm
          @confirm="$event => confirm(item.id)"
          title="确定删除该任务吗？"
        >
        <!-- @click.stop="toDel(itme.id)" -->
          <span class="el-icon-circle-close delIcon cp" slot="reference"></span>
        </el-popconfirm>
        
     </div>
    
     <div @click="change('add')" :class="['menu_item_box','cp',ctlActiveTaskId == 'add' ? 'menu-active-bg-image':'menuItemBg']">
      <el-popover
      class="ctl_popover"
      placement="bottom"
      v-model="popoverShow"
      width="280"
      trigger="click"> 
       <div>
        <el-form :model="form" :rules="rules" size="small" ref="ruleForm" labelWidth="80px">
            <el-form-item label="任务名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入任务名称" style="width:200px"></el-input>
            </el-form-item>
            <div class="tr">
              <el-button size="mini" @click="cancel" round>取消</el-button>
              <el-button size="mini" type="primary" @click="save" round>保存</el-button>
            </div>
        </el-form>
       </div>
       <div class="wh d-flex align-items-center justify-content-center font-14" slot="reference">
          <span class="mr-10 el-icon-plus font-16"></span> 新增任务
       </div>
      </el-popover>
     </div>
  </div>
</template>

<script>
  export default {
     
     data(){
       return {
         menuList:[],
         form: {
          "name": "",
         },
         rules:{
          name: [{
            required: true, message: '请输入任务名称', trigger: 'blur' 
          }]
         },
         popoverShow: false,
         resumeTaskId: ''
       }
     },
     created(){
        this.getTaskList()
     },
     computed:{
       ctlActiveTaskId() { return this.$store.getters.ctlActiveTaskId },
       isHas() {
          let result = false
          this.menuList.some(item =>{
            if(item.id == this.ctlActiveTaskId) return result = true
          })
          return result
       }
     },
     methods:{
        updateMenu(list,activeId){
           this.menuList = list
           if(!this.ctlActiveTaskId || !this.isHas){
              this.$store.commit('setCtlPageStutas',{taskId: res[0].id})
              this.$emit('change',list[0])
              return
           } 
           list.some(item => {
              if(item.id == this.ctlActiveTaskId) return this.$emit('change',item)
           })

           
           
        },
        change(item){
          if(item == 'add'){
            this.$store.commit('setCtlPageStutas',{taskId: item})
            this.resumeTaskId = !this.ctlActiveTaskId || this.ctlActiveTaskId == 'add' ? '' : this.ctlActiveTaskId
            return 
          } 
          // this.activeCode = item.id
          this.$store.commit('setCtlPageStutas',{taskId: item.id})
          this.$emit('change',item)
        },
        async getTaskList(){
           const res = await this.$api.IotTaskList()
           if(res && res.length){           
            
            this.$emit('onReq',res)
            this.menuList = res
            if(!this.ctlActiveTaskId || !this.isHas){
               this.$store.commit('setCtlPageStutas',{taskId: res[0].id})
               this.$emit('change',res[0])
               return
            } 
            res.some(item => {
              if(item.id == this.ctlActiveTaskId) return this.$emit('change',item)
            })
          }else{
            this.$emit('onReq',[])
            this.menuList = []
            this.$store.commit('setCtlPageStutas',{taskId: ''})
            this.$emit('change')
          }
           
           
        },
        save(){
          this.$refs.ruleForm.validate( async (valid)=> {
            if (valid) {
                 const res = await this.$api.AddIotTask(this.form)
           
                 if(res){
                
                  this.popoverShow = false
                  const addInfo = {
                     name: this.form.name,
                     id: res
                  }
                  this.menuList.push(addInfo)
                  this.$emit('onReq',this.menuList)
                  this.$store.commit('setCtlPageStutas',{taskId: res})
                  this.$emit('change',addInfo)
                  this.$nextTick(() => {
                     let scrollElem = this.$refs.menuRef;
                     scrollElem.scrollTo({ top:scrollElem.scrollHeight, behavior:'smooth' });
                  })
                 }
            }
          })
          
        },
        cancel(){
          this.popoverShow = false
          this.form.name= ''
          if(this.resumeTaskId) this.$store.commit('setCtlPageStutas',{taskId: this.resumeTaskId})
          
        },

        async confirm(id){
          const res = await this.$api.DelIotTask({id})
          this.getTaskList()
          this.$msg.success('删除成功')
        }
     }
  }
</script>

<style lang="scss" scoped>
.assignment_menu{
  width: 230px;
  overflow-y: auto;
  padding-right: 5px;
  box-sizing: border-box;
  .menu_item_box{
    width: 100%;
    height: 60px;
    
  }
  .taskItem{
    position: relative;
    line-height: 60px;
    text-align: center;
    
  }
  .delIcon{
      position: absolute;
      right: 10px;
      top: 10px;
  }
  .menu_item_box:not(:first-child){
     margin-top: 10px;
  }
}

</style>