<template>
  <div class="control_model_box">
    <div class="ctl_menu_box">
      <autoSearch 
       ref="autoSearch"
       placeholder="请输入关键字"
       :filterList="taskList"
       setValue="name"
       @select="handleSearch"
       @clear="clearSearch"
       :isScrollMargin="true"
       size="small" 
       class="mb-10">
       <template slot-scope="{ row }">
          <div class="auto_select_box">
              <span :class="['iconfont', row.iconCls || 'icon-tongdaozhuangtai', 'baseColor','autoIcon']"></span>
              <div class="info_box">
                  <p>{{ row.value }}</p>
                  <p>{{ row.devCode }}</p>
              </div>
          </div>
      </template>
      </autoSearch>
      <Menu
      class="menu_box"
      @change="taskChange"
      @onReq="taskReqList"
      ref="menuRef"
      ></Menu>
    </div>
    <container class="ctl_content_box" v-show="ctlShowTask">           
         <div slot="h-left" class="baseColor">{{ taskName }}</div>
         <div slot="h-right">
          <el-button @click="saveSort" v-show="srotData" size="small" class="ml-10 update_primary_but" type="primary"  :loading="false" plain>保存任务排序</el-button>
          <el-button @click="allCheck" size="small" class="ml-10 update_primary_but" type="primary"  :loading="false" v-if="taskPlanLength" plain>{{ checkName }}</el-button>
          <el-button size="small" class="ml-10 update_primary_but" type="primary" @click="toUpdate" v-if="activeId" :loading="updateLoading" plain>刷新</el-button>
         </div>
         <taskPlan  class="wh" ref="taskPlanRef" :taskId="this.activeId" @checkChange="checkChange"  :list="taskPlanList"></taskPlan>

    </container>
    <container class="ctl_content_box" v-show="!ctlShowTask">           
         <div slot="h-left">
           <span @click="toBack" class="back_icon el-icon-back cp"></span>
           <span class="baseColor">{{ taskName }}</span>
         </div>
         <div :class="['set_tabs_box','borderBase',ctlActiveSetCode == 2 ? 'set_active': '']" slot="h-center">
           <div class="tabs_body_box">
             <div @click='setCtlType("1")'>定时设置</div>
             <div @click='setCtlType("2")'>联控设置</div>
           </div>
         </div>

        <setTimeTask :list="timeCtlData" :taskId="this.activeId" v-if="ctlActiveSetCode == '1'"></setTimeTask>
        <setJointTask :list="jointCtlData" :taskId="this.activeId" v-else></setJointTask>
         
    </container>
    <toolBut @onCtl="toCtl" v-if="ctlShowTask && taskPlanLength"></toolBut>
  </div>
</template>

<script>
  import autoSearch from '@/components/localComponent/autoSearch.vue';
  import Menu from './components/menu.vue'
  import container from '../container.vue'
  import taskPlan from './components/taskPlan'
  import toolBut from './components/toolBut.vue'
  import setJointTask from './components/setJointTask'
  import setTimeTask from './components/setTimeTask'
  import bus from '@/libs/eventBus.js'
  import { deepClone } from '@/libs/util.js'
  export default {
     components: { autoSearch,Menu,container,taskPlan,toolBut,setJointTask,setTimeTask},
     data(){
      return{
        activeTaskInfo: null,
        taskPlanList: [],
        isAllCheck: false,
        // ctlTypeCode: 1,
        srotData: null,
        jointCtlData: [],
        timeCtlData: [],
        taskList: [],
        updateLoading: false,
        topicQueue: []
      }
     },
     created(){
       bus.$on('updateData', (code) =>{
          switch(code) {
             case 'time':
              // 定时执行
              this.toTimeCtl()
              // this.$store.commit('setCtlPageStutas',{setType})
             break;
             case 'joint':
              this.toJointCtl()
              break;
             default:
               this.reqIotTaskCmdList(this.activeId)
              break; 
          }
       })
       bus.$on('showSaveSrot', data => this.srotData = data)
       
     },
     destroyed(){
       this.pauseTopic()  
     },
     computed:{
       checkName() {
          return this.isAllCheck ? '取消全选' : '全选'
       },
       taskName(){
         return this.activeTaskInfo ?.name || ''
       },
       activeId(){
         return this.activeTaskInfo ?.id || ''
       },
       queueCodes(){
          return this.topicQueue.map(item => item.code)
       },
       ctlShowTask(){
         
         return this.$store.getters.ctlShowTask
       },
       ctlActiveSetCode(){

         return this.$store.getters.ctlActiveSetCode  
       },
       taskPlanLength(){
         return this.taskPlanList && this.taskPlanList.length
       }
     },
     methods:{
        taskReqList(list){
           this.taskList = list
        },
        setCtlType(code){
      
          if(code == 1) this.toTimeCtl()
          else this.toJointCtl()
          
          // this.$store.commit('setCtlPageStutas',{setType: code })
          //  this.ctlTypeCode = code
        },
        toBack(){
          this.$store.commit('setCtlPageStutas',{showTask: true})
          this.$store.commit('setCtlPageStutas',{setType: 1 })
          // this.reqIotTaskCmdList() 
        },
        toCtl(code){
          switch (code){
            case 'now':
              // 立即执行
              this.$refs.taskPlanRef.taskAllTaskCtl()
              break;
            case 'joint':
              // 联动执行
              this.toJointCtl()
              break;
            case 'time':
              // 定时执行
              this.toTimeCtl()
              break;
            case 'close':
              // 关闭执行
              this.$refs.taskPlanRef.taskAllColseTaskCtl()
              break;
          }
        },
        async toTimeCtl(){
          const res = await this.$api.IotTaskTimerList({ id: this.activeId })
          this.timeCtlData = res || []
          this.$store.commit('setCtlPageStutas',{setType: 1 })
          this.$store.commit('setCtlPageStutas',{showTask: false})
   
        },
        async toJointCtl(){

          const res = await this.$api.IotTaskLinkageList({ id: this.activeId })
          this.jointCtlData = res || []
          this.$store.commit('setCtlPageStutas',{setType: 2 })
          this.$store.commit('setCtlPageStutas',{showTask: false})
        },
        checkChange(){
           if(this.isAllCheck) this.isAllCheck= false
        },
        taskChange(info) {
            if(!info) {
              this.taskPlanList =  []
              this.$store.commit('setCtlPageStutas',{showTask: true})
              this.$store.commit('setCtlPageStutas',{setType: 1 }) 
              this.addDevTopic()
              return
            }
            if(this.activeTaskInfo){
              this.$store.commit('setCtlPageStutas',{showTask: true})
              this.$store.commit('setCtlPageStutas',{setType: 1 })
              this.activeTaskInfo = info
            }else if(!this.ctlShowTask && !this.activeTaskInfo){
              this.activeTaskInfo = info
              this.ctlActiveSetCode == 1 ? this.toTimeCtl() : this.toJointCtl()
          
            }else this.activeTaskInfo = info
            
            this.reqIotTaskCmdList() 
          
        },
        async reqIotTaskCmdList(){
           const res = await this.$api.IotTaskCmdList({id: this.activeId})
           if(this.updateLoading){
              setTimeout(() => {
                this.isAllCheck = false
                this.$refs.taskPlanRef.cancelCheck()
                this.updateLoading = false
                this.$msg.success('刷新成功')
              }, 500);
           }
           this.taskPlanList = res || []
           if(!this.taskPlanLength){
            this.$store.commit('setCtlPageStutas',{showTask: true})
            this.$store.commit('setCtlPageStutas',{setType: 1 })
           } 
           this.addDevTopic()
        },
        // 添加设备订阅
        addDevTopic(){

          let codes = this.taskPlanList.map(item => item.code)
          codes = [...new Set(codes)]
   
          //  1.保留相同设备订阅，删除多余订阅，新增新订阅
         
          let sameCode = codes.filter(code => this.queueCodes.includes(code) )
     
          this.queueCodes.map(code => {
            if(!sameCode.includes(code)) this.pauseTopic(code)
          })
          codes.map(code => {
              if(!sameCode.includes(code)) this.startTopic(code)
          })   
 
        },
        // 开始订阅
        startTopic(code){
         
          this.global_websocket_receive_message(code,frame => {
              const ObjFrame = JSON.parse(frame.body)
              this.$nextTick(() => {
                this.$refs.taskPlanRef ?.devTopicUpdate(ObjFrame)
              })
          }) 
          this.topicQueue.push({
              code
          })
        },
        // 暂停订阅
        pauseTopic(code){
            // 当code存在，暂停指定订阅，否则暂停全部
            this.topicQueue.map((item,i) => {
              if(code){
                 if(item.code == code){
                    this.global_websocket_unsubscribe(code)
                     this.topicQueue.splice(i,1)
                 }
              }else{
                this.global_websocket_unsubscribe(item.code)
              }
            })
            if(!code) this.topicQueue = []
        },
        allCheck() {
          this.isAllCheck = !this.isAllCheck
          this.isAllCheck ? this.$refs.taskPlanRef.allCheck() : this.$refs.taskPlanRef.cancelCheck()
        },
        saveSort() {
          this.$confirm(`执行器任务列表排序已改变，确认保存？`, "提示").then( async () => {
            const res = await this.$api.EditIotTaskCmdControlOrder({ids: this.srotData})
            if(res){
               this.srotData = null
               this.$msg.success('设置成功')
              // bus.$emit('updateData')   
            }
            
          })
        },
        toUpdate(){
          this.updateLoading = true
          this.reqIotTaskCmdList() 

        },
        handleSearch(data) {

            // let arrList = []
            let copyData = deepClone(this.taskList)
            copyData =  this.todo(copyData,data.id)
            // 更新menu数据，设置默认项
            this.$refs.menuRef.updateMenu(copyData,data.id)
        },
        clearSearch() {
          this.$refs.menuRef.getTaskList()  
        },
        todo(arr,id) {
          let index = 1,newArr = [], length = arr.length;
          for(var i = 0; i < length; i++){
              if(arr[i].id == id){
                  newArr[0] = arr[i]
              }else{
                  newArr[index++] = arr[i]
              }
          }

          return newArr

        }
        
       
   
       
     }
  }
</script>

<style lang="scss" scoped>
.control_model_box{
  height: 100%;
  display: flex;
  position: relative;
  .ctl_menu_box{
     height: 100%;
     width: 230px;
     .menu_box{
       height: calc(100% - 44px);
     }
  }
  .ctl_content_box{
     height: 100%;
     flex: 1;
  }
  .back_icon{
     width: 20px;
     height: 20px;
     line-height: 20px;
     text-align: center;
     border-radius: 12px;
     background-color: var(--el-color-primary);
     color: #28353F;
     font-size: 16px;
     margin-right: 6px;
  }
  .back_icon:hover{
     color: #fff;
  }
  .set_tabs_box{
     width: 180px;
     height: 30px;
     border-radius: 15px;
     position: relative;
     font-size: 14px;
     margin: 0 auto;
     .tabs_body_box{
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      z-index: 1;
      & > div{
         height: 100%;
         line-height: 30px;
         flex: 1;
         text-align: center;
         cursor: pointer;
      }
     }
  }
  .set_tabs_box::after{
    content: ' ';
    display: block;
    width: 90px;
    height: 30px;
    background-color: var(--el-color-primary);
    border-radius: 15px;
    position: absolute;
    left: -1px;
    top: 0;
    z-index: 0;
    transition: left 0.5s ease-in;
  }
  .set_active::after{
      left: 91px;
      transition: left 0.3s ease-in;
  }
}

</style>