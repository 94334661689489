<template>
  <div class="task_box pr">
    <ul>
      <li v-for="item of taskList" :key="item.name" @click="navChange(item.code)" :class="[active == item.code ? 'activebg baseColor' : '']">{{ text(item) }}</li>
    </ul>
    <div class="piont_box"></div>
  </div>
</template>

<script>
  export default {
    props: {
      infos: Object,
      type: String,
      taskList: {
        type: Array,
        default: () => [
          {
            name: "控制任务1",
            code: 0,
          },
          {
            name: "控制任务2",
            code: 1,
          },
        ],
      },
    },
    data() {
      return {
        active: 0,
      };
    },
    computed: {
      text() {
        return (item) => {
          let index = item.code + 1;
          let key = this.type + index;
          let isNotSet = this.infos?.[key];
          return isNotSet ? item.name : `${item.name} (未设置)`;
        };
      },
    },
    methods: {
      navChange(code) {
        this.active = code;
        this.$emit("change", code);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .task_box {
    width: 62%;
    height: 36px;
    margin: 0 auto 20px auto;
    & > ul {
      width: 100%;
      height: 100%;
      border: 1px solid #344552;
      background-color: #202e37;
      display: flex;
      border-radius: 5px;
      font-size: 14px;
      & > li {
        flex: 1;
        height: 100%;
        text-align: center;
        line-height: 36px;
      }
      .activebg {
        background-color: #344552;
      }
    }
    .piont_box {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      border: 3px solid #202e37;
      background-color: #344552;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .remarks_box {
      position: absolute;
      right: -25px;
      top: 50%;
      margin-top: -9px;
    }
  }
</style>
